(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@babel/runtime-corejs3/helpers/defineProperty"), require("neo4j-driver"), require("@babel/runtime-corejs3/core-js/get-iterator"), require("@babel/runtime-corejs3/regenerator"), require("@babel/runtime-corejs3/core-js-stable/object/values"), require("@babel/runtime-corejs3/core-js-stable/object/keys"), require("@babel/runtime-corejs3/helpers/classCallCheck"), require("@babel/runtime-corejs3/helpers/createClass"), require("@babel/runtime-corejs3/core-js-stable/instance/for-each"), require("@babel/runtime-corejs3/helpers/asyncToGenerator"), require("vis-network/dist/vis-network.min"), require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors"), require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor"), require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols"), require("@babel/runtime-corejs3/core-js-stable/promise"), require("@babel/runtime-corejs3/core-js-stable/object/define-property"), require("@babel/runtime-corejs3/core-js-stable/object/define-properties"), require("@babel/runtime-corejs3/core-js-stable/instance/filter"), require("@babel/runtime-corejs3/core-js-stable/set-timeout"), require("@babel/runtime-corejs3/core-js-stable/instance/map"), require("@babel/runtime-corejs3/core-js-stable/instance/concat"), require("@babel/runtime-corejs3/core-js-stable/array/is-array"), require("@babel/runtime-corejs3/helpers/typeof"), require("@babel/runtime-corejs3/core-js-stable/symbol"), require("vis-network/dist/vis-network.min.css"));
	else if(typeof define === 'function' && define.amd)
		define(["@babel/runtime-corejs3/helpers/defineProperty", "neo4j-driver", "@babel/runtime-corejs3/core-js/get-iterator", "@babel/runtime-corejs3/regenerator", "@babel/runtime-corejs3/core-js-stable/object/values", "@babel/runtime-corejs3/core-js-stable/object/keys", "@babel/runtime-corejs3/helpers/classCallCheck", "@babel/runtime-corejs3/helpers/createClass", "@babel/runtime-corejs3/core-js-stable/instance/for-each", "@babel/runtime-corejs3/helpers/asyncToGenerator", "vis-network/dist/vis-network.min", "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors", "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor", "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols", "@babel/runtime-corejs3/core-js-stable/promise", "@babel/runtime-corejs3/core-js-stable/object/define-property", "@babel/runtime-corejs3/core-js-stable/object/define-properties", "@babel/runtime-corejs3/core-js-stable/instance/filter", "@babel/runtime-corejs3/core-js-stable/set-timeout", "@babel/runtime-corejs3/core-js-stable/instance/map", "@babel/runtime-corejs3/core-js-stable/instance/concat", "@babel/runtime-corejs3/core-js-stable/array/is-array", "@babel/runtime-corejs3/helpers/typeof", "@babel/runtime-corejs3/core-js-stable/symbol", "vis-network/dist/vis-network.min.css"], factory);
	else if(typeof exports === 'object')
		exports["NeoVis"] = factory(require("@babel/runtime-corejs3/helpers/defineProperty"), require("neo4j-driver"), require("@babel/runtime-corejs3/core-js/get-iterator"), require("@babel/runtime-corejs3/regenerator"), require("@babel/runtime-corejs3/core-js-stable/object/values"), require("@babel/runtime-corejs3/core-js-stable/object/keys"), require("@babel/runtime-corejs3/helpers/classCallCheck"), require("@babel/runtime-corejs3/helpers/createClass"), require("@babel/runtime-corejs3/core-js-stable/instance/for-each"), require("@babel/runtime-corejs3/helpers/asyncToGenerator"), require("vis-network/dist/vis-network.min"), require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors"), require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor"), require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols"), require("@babel/runtime-corejs3/core-js-stable/promise"), require("@babel/runtime-corejs3/core-js-stable/object/define-property"), require("@babel/runtime-corejs3/core-js-stable/object/define-properties"), require("@babel/runtime-corejs3/core-js-stable/instance/filter"), require("@babel/runtime-corejs3/core-js-stable/set-timeout"), require("@babel/runtime-corejs3/core-js-stable/instance/map"), require("@babel/runtime-corejs3/core-js-stable/instance/concat"), require("@babel/runtime-corejs3/core-js-stable/array/is-array"), require("@babel/runtime-corejs3/helpers/typeof"), require("@babel/runtime-corejs3/core-js-stable/symbol"), require("vis-network/dist/vis-network.min.css"));
	else
		root["NeoVis"] = factory(root[undefined], root[undefined], root[undefined], root[undefined], root[undefined], root[undefined], root[undefined], root[undefined], root[undefined], root[undefined], root[undefined], root[undefined], root[undefined], root[undefined], root[undefined], root[undefined], root[undefined], root[undefined], root[undefined], root[undefined], root[undefined], root[undefined], root[undefined], root[undefined], root[undefined]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__5__, __WEBPACK_EXTERNAL_MODULE__6__, __WEBPACK_EXTERNAL_MODULE__7__, __WEBPACK_EXTERNAL_MODULE__8__, __WEBPACK_EXTERNAL_MODULE__9__, __WEBPACK_EXTERNAL_MODULE__10__, __WEBPACK_EXTERNAL_MODULE__11__, __WEBPACK_EXTERNAL_MODULE__12__, __WEBPACK_EXTERNAL_MODULE__13__, __WEBPACK_EXTERNAL_MODULE__14__, __WEBPACK_EXTERNAL_MODULE__15__, __WEBPACK_EXTERNAL_MODULE__16__, __WEBPACK_EXTERNAL_MODULE__17__, __WEBPACK_EXTERNAL_MODULE__18__, __WEBPACK_EXTERNAL_MODULE__19__, __WEBPACK_EXTERNAL_MODULE__20__, __WEBPACK_EXTERNAL_MODULE__21__, __WEBPACK_EXTERNAL_MODULE__22__, __WEBPACK_EXTERNAL_MODULE__23__, __WEBPACK_EXTERNAL_MODULE__24__) {
return 